
































import accountComputedMixin from "@/components/account/account-computed";
import CompanyDetailsForm from "./account-company-details.vue";
import CompanyAddressForm from "./account-company-address.vue";
import InvoicePaymentsForm from "./account-invoice-payments.vue";
import BusinessLocation from "./account-business-default-settings.vue";
import ButtonActionPrimary from "@/components/shared/buttons/button-action-primary.vue";
import moment from "moment";
import Loader from "@/components/shared/loaders/list-loader.vue";
import { checkFieldsValidity } from "@/utils/helpers";
import { UserRoleType } from '@/enum/userRoleType';
import ConstantValues from '@/plugins/constantValues';

export default {
    name: "AccountBusinessForm",
    mixins: [accountComputedMixin],
    components: {
        CompanyDetailsForm,
        CompanyAddressForm,
        InvoicePaymentsForm,
        ButtonActionPrimary,
        BusinessLocation,
        Loader,
    },
    computed: {
        isFieldsValid() {
            if (this.userRole == UserRoleType.Manager) {
                return true;
            } else {
                if (
                    checkFieldsValidity([
                        this.accountBusinessProfile.title,
                        this.businessProfilePrefix,
                        this.accountBusinessProfile.address.postalCode,
                        this.accountBusinessProfile.address.addressLine1,
                        this.accountBusinessProfile.config.timezone,
                        this.accountBusinessProfile.config.currency
                    ]) &&
                    this.businessProfilePrefix.length >=
                    ConstantValues.defaultBusinessProfilePrefixMinLength
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        },
    },
    mounted() {
        this.$store.dispatch("getGlobalCurrenciesList");
        this.$store.dispatch("getGlobalTimeZonesList");
        this.$store.dispatch("getDefaultTaxesNameList");
        this.$store.dispatch("getAccountBussinessProfile").then((rs) => {
            this.$store.dispatch("getGoogleMapToShowDefaultMarker");
            this.businessProfilePrefix = this.accountBusinessProfile.shortTitle;
        });
    },
    methods: {
        updateAccountBusinessProfile() {
            const crDate = moment().format("YYYY-MM-DD");
            const businessDto = {
                title: this.accountBusinessProfile.title,
                shortTitle: this.businessProfilePrefix.toUpperCase(),
                address: this.accountBusinessProfile.address,
                registrationNumber: this.accountBusinessProfile.registrationNumber,
                vatNumber: this.accountBusinessProfile.vatNumber,
                paymentInstructions: this.accountBusinessProfile.paymentInstructions,
                config: {
                    workingStartTime: moment
                        .utc(
                            moment(
                                crDate +
                                " " +
                                this.accountBusinessProfile.config.workingStartTimeConverted +
                                ":00"
                            ).utc()
                        )
                        .format(),
                    workingEndTime: moment
                        .utc(
                            moment(
                                crDate +
                                " " +
                                this.accountBusinessProfile.config.workingEndTimeConverted +
                                ":00"
                            ).utc()
                        )
                        .format(),
                    allowedBreakTimeADayInMinutes:
                        this.accountBusinessProfile.config.allowedBreakTimeADayInMinutes,
                    defaultLatitude: this.startingLat
                        ? this.startingLat
                        : ConstantValues.defaultBusinessLatitude,
                    defaultLongitude: this.startingLng
                        ? this.startingLng
                        : ConstantValues.defaultBusinessLongitude,
                    defaultAreaMeasurementUnit:
                        this.accountBusinessProfile.config.defaultAreaMeasurementUnit,
                    defaultColor: this.accountBusinessProfile.config.defaultColor,
                    timezoneId: this.accountBusinessProfile.config.timezone.id,
                    currencyId: this.accountBusinessProfile.config.currency.id,
                    invoiceInstructions: this.accountBusinessProfile.config.invoiceInstructions,
                    invoiceTermsInDays: this.accountBusinessProfile.config.invoiceTermsInDays || 0,
                    salesTaxName: this.accountBusinessProfile.config.salesTaxName,
                    salesTaxRegistrationNumber: this.accountBusinessProfile.config.salesTaxName ? this.accountBusinessProfile.config.salesTaxRegistrationNumber : null,
                    salesTaxRates: this.accountBusinessProfile.config.salesTaxName ? this.accountBusinessProfile.config.salesTaxRates : []
                },
            };
            this.$store
                .dispatch("updateAccountBusinessProfile", businessDto)
                .then(() => {
                    this.$store.dispatch("getGoogleMapToShowDefaultMarker");
                });
        },
    },
};
