



















































































































































































































import TeamJobsComputed from "@/components/team-jobs/team-jobs-computed/_team-jobs-computed";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import moment from "moment";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import { getTimeDetailsFormatted } from "@/utils/helpers/time-details-helpers";
import TeamJobsCommon from "@/components/team-jobs/team-jobs-details/team-jobs-details-common";
import { getHoursAndMins, getMinutesFromSeconds, getZeroPadding } from "@/utils/helpers";
import { TeamJobStatusType } from "@/enum/teamJobStatusType";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import { acceptableBillingUnitsForBillableQuantityUpdate } from '@/utils/constants';

export default {
  name: "TeamJobsMetadata",
  mixins: [TeamJobsComputed, TeamJobsCommon],
  components: {
    VueCtkDateTimePicker,
    VueTimepicker,
    Button
  },
  computed: {
    isTimeRecordsSelectable: {
        get() {       
            return this.teamJobDetails 
                    && this.teamJobOperation
                    && ((this.teamJobDetails.status == TeamJobStatusType.AwaitingCompletion 
                        || (this.teamJobDetails.status == TeamJobStatusType.Completed && !this.teamJobDetails.isApprovedForInvoicing) 
                        || this.teamJobDetails.status == TeamJobStatusType.PartCompleted))
                    && this.teamJobTimeDetailsList.length > 0
                    && acceptableBillingUnitsForBillableQuantityUpdate.includes(this.teamJobOperation.billingUnit)
        }
    },
    isAnyTimeRecordSelected: {
        get() {
            return this.teamJobSelectedTimeRecords.length > 0
        }
    }
  },
  watch: {
    isAllTimeRecordsSelected: {
        handler: function (val) {
            if (val) 
                this.teamJobSelectedTimeRecords = this.teamJobTimeDetailsList.map((_, index) => index)
            else 
                this.teamJobSelectedTimeRecords = []
        }
    }
  },
  methods: {
    handleWorkTimingsManualUpdate(idx) {
      if (this.teamJobsLoader) return;

      const tempTimeRecord = Object.assign(
        {},
        this.teamJobTempTimeDetailsList[idx]
      );
      const timeRecord = this.teamJobTimeDetailsList[idx];
      const startingTime = moment(
        moment(timeRecord.startTime).format("DD-MM-YYYY HH:mm:ss"),
        "DD-MM-YYYY hh:mm:ss"
      );
      const endingTime = moment(
        moment(timeRecord.endTime).format("DD-MM-YYYY HH:mm:ss"),
        "DD-MM-YYYY hh:mm:ss"
      );
      const startTimeEndTimeDifferenceInSeconds = endingTime.diff(
        startingTime,
        "seconds"
      );

      const totaldownTimeInSeconds =
        parseInt(timeRecord.downTimeFormatted.HH) * 3600 +
        parseInt(timeRecord.downTimeFormatted.mm) * 60;
      const totalBreakTimeInSeconds =
        parseInt(timeRecord.breakTimeFormatted.HH) * 3600 +
        parseInt(timeRecord.breakTimeFormatted.mm) * 60;

      let startEndTimeDifferenceInSeconds =
        startTimeEndTimeDifferenceInSeconds -
        (totaldownTimeInSeconds + totalBreakTimeInSeconds);
      if (startEndTimeDifferenceInSeconds > 0) {
        this.teamJobTimeDetailsList[idx].workTimeFormatted =
          startTimeEndTimeDifferenceInSeconds -
          totalBreakTimeInSeconds -
          totaldownTimeInSeconds;
        this.teamJobTimeDetailsList[idx].totalTimeFormatted =
          startTimeEndTimeDifferenceInSeconds;
        this.teamJobTimeDetailsList[idx].workTimeInSeconds =
          startTimeEndTimeDifferenceInSeconds -
          totalBreakTimeInSeconds -
          totaldownTimeInSeconds;
      } else {
        this.teamJobTimeDetailsList[idx].startTime = tempTimeRecord.startTime;
        this.teamJobTimeDetailsList[idx].endTime = tempTimeRecord.endTime;
        this.teamJobTimeDetailsList[idx].travelTimeFormatted =
          getTimeDetailsFormatted(tempTimeRecord.travelTimeInSeconds);
        this.teamJobTimeDetailsList[idx].downTimeFormatted =
          getTimeDetailsFormatted(tempTimeRecord.downTimeInSeconds);
        this.teamJobTimeDetailsList[idx].breakTimeFormatted =
          getTimeDetailsFormatted(tempTimeRecord.breakTimeInSeconds);
        this.teamJobTimeDetailsList[idx].workTimeFormatted =
          tempTimeRecord.workTimeInSeconds;
        this.teamJobTimeDetailsList[idx].workTimeInSeconds =
          tempTimeRecord.workTimeInSeconds;
      }

      this.updateSelectedTimeRecordsTotalWorkHours()
    },
    onChangeStartEndTime(idx) {
      this.onChangeTimeRecords();
      this.handleWorkTimingsManualUpdate(idx);
    },
    onChangeWorkTime(idx) {
      const tempTimeRecord = Object.assign(
        {},
        this.teamJobTempTimeDetailsList[idx]
      );
      if (
        this.teamJobTimeDetailsList[idx].workTimeFormatted !=
        tempTimeRecord.workTimeFormatted
      ) {
        this.enableTeamJobDiscard();
      }
    },
    onChangeTimeRecords() {
      this.updateSelectedTimeRecordsTotalWorkHours();
      this.enableTeamJobDiscard();
    },
    getTimeInHoursAndMins(timeDurationInSeconds: number) {
      const { hours, minutes } = getHoursAndMins(timeDurationInSeconds);
      return `${getZeroPadding(hours, 2)}:${getZeroPadding(minutes, 2)}`;
    },
    updateSelectedTimeRecordsTotalWorkHours() {
      this.teamJobSelectedTimeRecordsHours = 0

        if (this.teamJobSelectedTimeRecords) {
            this.teamJobSelectedTimeRecords.forEach(timeRecordIndex => {
                this.teamJobSelectedTimeRecordsHours += this.teamJobTimeDetailsList[timeRecordIndex].workTimeInSeconds
            })
        }
    },
    toggleSelectedTimeRecordsTotalWorkHours() {
        this.$nextTick(() => {
            if (this.isAllTimeRecordsSelected)
              this.updateSelectedTimeRecordsTotalWorkHours()
            else
              this.teamJobSelectedTimeRecordsHours = 0
        });
    },
    setWorkHoursAsBillableQuantity() {
        this.$store.dispatch('setWorkHoursAsBillableQuantity', this.teamJobSelectedTimeRecordsHours)
        this.enableTeamJobDiscard()
    },
    getMinutesFromSeconds(seconds: number) {
        return getMinutesFromSeconds(seconds)
    },
  },
};
