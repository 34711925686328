import { apiUsers, apiFarmers, apiJobs, apiInvoicesDraft, apiInvoicesOpen, apiInvoicesPaid, apiInvoices, apiTaxesVat, apiProducts } from '@/utils/endpoints';
import { devLogger, getCommaSeparatedQuery, getListLengthAbsolute, notify } from '@/utils/helpers';
import requests from '@/utils/requests';
import buildUrl from 'build-url';
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
import StringConstants from '@/plugins/stringConstants';
import ConstantValues from '@/plugins/constantValues';
import { InvoiceEmailStatusType } from '@/enum/invoiceStatusType';
import { JobInvoicingStatusType } from '@/enum/jobInvoicingStatusType';
import { defaultPageLimitSize, defaultPageOffsetSize, limitQuery, offsetQuery } from '@/utils/constants';

export const actions = {
    async getBusinessProfileForInvoice({ rootState, state, commit }, profileId) {
        state.invoiceLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + profileId
        });
        const result = await requests.getData(url, true);
        if (result != null) {
            if (result.data != null) {
                commit('setBusinessProfileForInvoice', result.data);
                state.invoiceLoader = false;
                return result.data;
            }
        } else {
            state.invoiceLoader = false;
            devLogger().logError(result)
        }
    },

    async getCustomerDetailsForInvoice({ rootState, state, commit }, customerId) {
        state.invoiceLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + '/' + customerId
        });
        const result = await requests.getData(url, true);

        if (result != null) {
            if (result.data != null) {
                if (result.data && result.data.email == null) {
                    notify(StringConstants.invoiceCustomerInvalidEmailWarning, 'fail');
                } else if (result.data.businessProfile &&
                    result.data.businessProfile.address &&
                    result.data.businessProfile.shortTitle &&
                    (result.data.businessProfile.shortTitle.length >= ConstantValues.defaultBusinessProfilePrefixMinLength &&
                        result.data.businessProfile.shortTitle.length <= ConstantValues.defaultBusinessProfilePrefixLength) &&
                    result.data.businessProfile.address.addressLine1 &&
                    result.data.businessProfile.address.postalCode) {
                    commit('setCustomerProfileForInvoice', result.data);
                } else {
                    notify(StringConstants.userCustomerBusinessProfileMissingWarning, 'fail');
                }
            }
        } else {
            devLogger().logError(result)
        }
    },

    async getCustomersListForInvoice({ state, commit, rootState, dispatch }) {
        if (getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) {
            state.customersListForInvoiceLoader = true;
            dispatch("jsonToQueryString", rootState.filterOptions);
            const url = buildUrl(rootState.baseUrl, {
                path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + rootState.tempUrl
            });
            const result = await requests.getData(url, true);
            state.customersListForInvoiceLoader = false;

            if (result != null && 'data' in result) {
                if (result.data != null) {
                    state.customersListForInvoiceSize = result.data.size;
                    rootState.listCount = result.data.value.length;
                    if (result.data.value.length > 0) {
                        commit('setCustomersListForInvoice', result.data.value);
                    }
                    if (rootState.offset == 0) {
                        commit('setNoDataStatus', result.data.value);
                    }
                }
            }
        }
    },

    async getJobsListForInvoice({ state, commit, rootState, dispatch }, customerIdForJobs) {
        state.invoiceLoader = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        let url = '';
        const initialPagingFilters = `${apiJobs}?${offsetQuery}=${defaultPageOffsetSize}&${limitQuery}=${defaultPageLimitSize}`;

        if (state.jobsForInvoiceSearchText != null && state.jobsForInvoiceSearchText.length != 0) {
            url = buildUrl(rootState.baseUrl, {
                path: initialPagingFilters + '&SearchText=' + state.jobsForInvoiceSearchText + (customerIdForJobs ? ('&CustomerIds=' + customerIdForJobs) : '') + '&InvoicingStatus=' + JobInvoicingStatusType.Approved
            });
        } else {
            url = buildUrl(rootState.baseUrl, {
                path: initialPagingFilters + (customerIdForJobs ? ('&CustomerIds=' + customerIdForJobs) : '') + '&InvoicingStatus=' + JobInvoicingStatusType.Approved
            });
        }

        const result = await requests.getData(url);
        if (result != null && 'data' in result) {
            if (result.data != null) {
                state.jobsListForInvoice = [];
                rootState.listCount = result.data.value.length;
                commit('setJobsListForInvoice', result.data.value);
                return result.data;
            }
        }
    },

    async getInvoicesList({ state, commit, rootState, dispatch }) {
        if (getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) {
            rootState.listLoading = true
            dispatch("jsonToQueryString", rootState.filterOptions);

            let url = buildUrl(rootState.baseUrl, {
                path: apiInvoices + rootState.tempUrl
            });

            if (state.invoiceFilterStatusSelected != null && state.invoiceFilterStatusSelected.length > 0) {
                url += ('&Statuses=' + state.invoiceFilterStatusSelected);
            }
            if (state.invoiceFilterEmailStatusSelected != null && state.invoiceFilterEmailStatusSelected !== 'All') {
                url += '&EmailStatus=' + InvoiceEmailStatusType[state.invoiceFilterEmailStatusSelected];
            }

            const result = await requests.getData(url);
            if (result != null && 'data' in result) {
                if (result.data != null) {
                    state.listCount = result.data.value.length;
                    state.totalRowCount = result.data.size;
                    state.offset = result.data.offset;
                    if (result.data.value.length > 0) {
                        commit('setInvoicesList', result.data.value);
                    }
                    if (rootState.offset == 0) {
                        commit('setNoListDataStatus', result.data.value);
                    }
                }
                rootState.listLoading = false;
            }
        }
    },

    async createInvoiceDraftPreview({ rootState, state }, data) {
        state.invoiceLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiInvoicesDraft
        });
        const result = await requests.postData(url, data);
        if (result[0] != null) {
            state.invoiceLoader = false;
            return result[0];
        } else {
            state.invoiceLoader = false;
            return false;
        }
    },

    async createInvoiceDraft({ rootState, state }, data) {
        state.invoiceLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiInvoices
        });
        const result = await requests.postData(url, data);
        if (result[0] != null) {
            state.invoiceLoader = false;
            state.draftedInvoiceIdToOpen = result[0].id;
            state.canOpenInvoice = true;
            return result[0];
        } else {
            state.invoiceLoader = false;
        }
    },

    async updateInvoiceDraft({ rootState, state, dispatch, commit }, data) {
        state.invoiceLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiInvoices + '/' + data[1]
        });
        const result = await requests.putData(url, data[0]);
        if (result[0] != null) {
            state.invoiceLoader = false;
            state.draftedInvoiceIdToOpen = result[0].id;
            notify(StringConstants.invoiceDraftUpdatedText, 'success');
            state.canOpenInvoice = true;
            dispatch('getInvoiceDetails', data[1]);
            return result[0];
        } else {
            state.invoiceLoader = false;
        }
    },


    async createInvoiceFinalize({ rootState, state }, id) {
        state.invoiceLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiInvoices + '/' + id + apiInvoicesOpen
        });
        const result = await requests.postData(url, {
            value: state.invoiceSend
        });
        if (result[0] != null) {
            state.invoiceLoader = false;
            if (state.invoiceSend) {
                notify(StringConstants.invoiceCreatedAndSent, 'success');
            } else {
                notify(StringConstants.invoiceCreatedSuccessMessage, 'success');
            }
            state.isInvoiceOpened = true;
            return result[0];
        } else {
            state.invoiceLoader = false;
        }
    },

    async getInvoiceDetails({ state, commit, rootState, dispatch }, id) {
        if (rootState.loginUserRole != 1) {
            dispatch("jsonToQueryString", rootState.filterOptions);
            const url = buildUrl(rootState.baseUrl, {
                path: localStorage.getItem(ConstantValues.ownerId) + apiInvoices + '/' + id
            });
            const result = await requests.getData(url);
            if (result != null && 'data' in result) {
                if (result.data != null) {
                    switch (result.data.status) {
                        case 0:
                            state.isInvoiceOpened = false;
                            state.canOpenInvoice = true;
                            state.draftedInvoiceIdToOpen = id;
                            break;
                        case 1:
                        case 2:
                            state.isInvoiceOpened = true;
                            state.canOpenInvoice = false;
                            break;
                    }
                    commit('setInvoicePreviewDetails', result.data);
                    return result.data;
                }
            }
        }
    },

    async setCustomersFilter({ commit, state }, data) {
        if (state.customersSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.customersSearchText]);
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
        commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);
    },

    async setJobsForInvoiceFilter({ commit, state }) {
        if (state.jobsForInvoiceSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.jobsForInvoiceSearchText]);
        }
    },

    setCustomersForInvoicesSearchFilters({ commit, state }, _) {
        commit('setClearFilterOptions');
        if (state.invoicesListFilterCustomerSearchText != null && state.invoicesListFilterCustomerSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.invoicesListFilterCustomerSearchText]);
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, state.invoicesListFilterCustomersList.length]);
        commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
    },

    async getCustomersForInvoicesSearch({ state, rootState, commit, dispatch }) {
        state.invoicesListFilterCustomersDropdownLoader = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + rootState.tempUrl
        });
        const result = await requests.getData(url);
        state.invoicesListFilterCustomersDropdownLoader = false;
        if (result != null && 'data' in result) {
            if (result.data != null) {
                if (result.data.value.length <= 0) {
                    rootState.searchNotify = StringConstants.noSearchResultFoundText;
                }
                else {
                    commit('setCustomersListForInvoicesSearch', result.data.value);
                    commit('setCustomersListSizeForInvoicesSearch', result.data.size);
                }
            }
            return result
        }
    },

    async fnSetInvoicesFilter({ commit, state }, data) {
        commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
        commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);
        state.invoiceSearchText && commit('setFilterOptions', [ConstantValues.searchTextQuery, state.invoiceSearchText]);
        state.invoicesListFilterCustomerValue && commit('setFilterOptions', ['CustomerId', state.invoicesListFilterCustomerValue]);
        state.invoicesListFilterStartDate && commit('setFilterOptions', ['Start', state.invoicesListFilterStartDate]);
        state.invoicesListFilterEndDate && commit('setFilterOptions', ['End', state.invoicesListFilterEndDate]);
    },

    async markInvoiceAsPaid({ rootState, state }, id) {
        state.invoiceLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiInvoices + '/' + id + apiInvoicesPaid
        });
        const result = await requests.postData(url, {
            "value": 0
        });
        state.invoiceLoader = false;
        if (result[0] != null) {
            notify(StringConstants.invoiceUpdateSuccessfully, 'success');
            return result[0];
        }
    },

    async getJobsListForPreview({ state, commit, rootState, dispatch }, jobIds) {
        state.invoiceJobsPreviewLoader = true;
        state.invoiceJobsForPreview = [];

        const csJobIds = getCommaSeparatedQuery(jobIds, 'Ids');
        dispatch("jsonToQueryString", rootState.filterOptions);
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + '?' + csJobIds
        });
        const result = await requests.getData(url);
        state.invoiceJobsPreviewLoader = false;
        if (result != null && 'data' in result) {
            if (result.data != null) {
                commit('setJobsListForPreview', result.data.value);
                return result.data;
            }
        }
    },

    async deleteInvoice({ rootState, state, commit }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiInvoices + '/' + data
        });
        const result = await requests.deleteData(url);
        if (result && result.status == 200) {
            notify(StringConstants.invoiceDeletedSuccessfully, 'success');
            const invoiceIndex = state.invoicesList.findIndex(invoice => invoice.id === data);
            if (invoiceIndex > -1) {
                commit('removeInvoiceFromList', invoiceIndex);
                commit('updateInvoicePagingAfterListItemRemoval');
            }
        } else {
            notify(StringConstants.invoiceFailedToDelete, 'fail');
        }
    },

    async sendInvoiceMail({ rootState, state, dispatch, commit }, id) {
        state.invoiceLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiInvoices + '/' + id + '/send'
        });
        const result = await requests.postData(url, null);
        state.invoiceLoader = false;
        if (result[0] == true) {
            notify(StringConstants.invoiceSentSuccessfully, 'success');
            dispatch('getInvoiceDetails', id).then(updatedInvoice => commit('updateInvoiceDetailsInInvoiceList', updatedInvoice));
            return result[0];
        } else {
            notify(StringConstants.invoiceFailedToSend, 'fail');
        }
    },

    async downloadInvoice({ rootState, state }, invoiceId) {
        state.invoiceLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + apiInvoices + '/' + invoiceId + '/report'
        });

        const result = await requests.getData(url);
        state.invoiceLoader = false;
        if (result.status == 200 && 'data' in result) {
            const attachmentLink = document.createElement("a");
            attachmentLink.href = result.data;
            attachmentLink.download = invoiceId;
            attachmentLink.target = "_blank";
            attachmentLink.click();
        }
        else {
            notify(StringConstants.invoiceUnableToDownload, "fail");
        }
    },

    async setInvoicesParameterFilters({ state, commit }) {
        (state.invoiceSearchText?.length > 0) && commit('setFilterOptions', ["SearchText", state.invoiceSearchText]);
        state.invoiceFilterStatusSelected && commit('setFilterOptions', ["Statuses", state.invoiceFilterStatusSelected]);
    },

    async setInvoicesDataFilter({ dispatch }) {
        dispatch('setInvoicesParameterFilters');
    },
    async setProductsFilterForInternalInvoice({ state, commit }) {
        commit('setClearFilterOptions');
        if (state.invoiceInternalLineItemSearchText != null && state.invoiceInternalLineItemSearchText.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.invoiceInternalLineItemSearchText]);
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, getListLengthAbsolute().getListLengthActual(state.productListForInternalInvoice)]);
        commit('setFilterOptions', [ConstantValues.limitQuery, ConstantValues.defaultPageLimitSize]);
    },
    async getProductsListFilteredForInternalInvoice({ state, rootState, commit, dispatch }) {
         state.productFeatchingLoader = true;
         dispatch("jsonToQueryString", rootState.filterOptions);
         const url = buildUrl(rootState.baseUrl, {
             path: localStorage.getItem(ConstantValues.ownerId) + apiProducts + rootState.tempUrl
         });
         const result = await requests.getData(url);
         state.productFeatchingLoader = false;
         if (result != null && 'data' in result) {
             if (result.data != null) {
                 if (result.data.value.length <= 0) {
                     rootState.searchNotify = StringConstants.noSearchResultFoundText;
                 }
                 else {
                     commit('setProductListForInternalInvoice', result.data.value);
                 }
             }
             return result
         }
     }
}