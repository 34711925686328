













































import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { TeamJobMetaData } from '@/data/models/TeamJobMetaData';
import { mapMutations } from 'vuex';
import { TeamJobOperatorChecklistResourceType } from '@/enum/teamJobOperatorChecklistResourceType';
import { getChecklistItemFromResource, getPageParam } from '@/utils/helpers/jobs-helpers';
import { TeamJobProduct } from '@/data/models/TeamJobProduct';
import { listContainsItem, notify } from '@/utils/helpers';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import { TeamJobStatusType } from '@/enum/teamJobStatusType';
import { MachineType } from '@/enum/machineType';
import LoadMoreDropdown from "@/components/shared/load-more-jobs-dropdown.vue";
import ConstantValues from '@/plugins/constantValues';

export default {
    name: "TeamJobsOperation",
    mixins: [TeamJobsComputed, TeamJobsCommon],
    components: {
        LoadMoreDropdown
    },
    props: {
        minimal: Boolean
    },
    data(){
        return{
            searchTimer: null
        }
    },
    watch: {
        teamJobOperation(newOperation, prevOperation) {
            if (this.teamJobOperation 
                && newOperation 
                && prevOperation 
                && newOperation.billingQuantities?.length > 0 
                && prevOperation.billingQuantities?.length > 0 
                && JSON.stringify(newOperation.billingQuantities) !== JSON.stringify(prevOperation.billingQuantities) 
                && prevOperation.billingQuantities[0].billingQuantity > 0) {
                    if (this.teamJobOperation && this.teamJobOperation.billingQuantities?.length > 0) {
                        this.teamJobOperation.billingQuantities[0].billingQuantity = prevOperation.billingQuantities[0].billingQuantity;
                    }
            }
        }
    },
    methods: {
        ...mapMutations({
            clearFilterOptions: "setClearFilterOptions",
        }),
        applySearchJobOperationTimeout(e) {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(() => {
                this.searchTeamJobOperations();
            }, ConstantValues.defaultSearchTimeout);
        },
        searchTeamJobOperations() {
            this.clearFilterOptions();
            this.teamJobOperationsList = [];
            this.$store.dispatch('setTeamJobOperationsFilters').then(res => {
                if (res != false) {
                    this.$store.dispatch('getTeamJobOperations');
                    if (this.teamJobOperationsSearchText == '') {
                        this.clearFilterOptions();
                    }
                }
            });
        },
        productExistsInProductsList(productId) {
            return listContainsItem(this.teamJobProducts, [ConstantValues.defaultObjectIdentifier], productId)
        },
        filterResourcesByOperation() {
            this.teamJobOperationsSearchText = null;
            if (this.teamJobOperation) {
                this.teamJobMetaDataList = this.teamJobMetaDataList.filter(x => x.id != null);
                this.teamJobOperation.loadInformationList.forEach(loadInfo => {
                    this.teamJobMetaDataList.push(new TeamJobMetaData().getTeamJobMetaData(loadInfo))
                })

                this.$store.dispatch('getTeamJobOperationResource', this.teamJobOperation.id).then(res => {
                    if(res?.response?.status === 404) {
                        notify(this.$stringConstants('teamJobOperationNotFoundWarning'), 'fail')
                    } else {
                        const { data } = res;

                        if (data.products && data.products.length) {
                            data.products.forEach(product => {
                                const newProduct = new TeamJobProduct().getTeamJobProduct(product);
                                if (!this.productExistsInProductsList(newProduct.id)) {
                                    this.teamJobProducts.push(newProduct);
                                }
                            })
                        }

                        if(res?.data?.vehicles && res?.data?.vehicles?.length) {
                            this.$store.commit('setTeamJobOperatorMachinesDropdown', {
                                machineType: MachineType.Vehicle, 
                                machinesList: res?.data?.vehicles || []
                            })
                        }

                        if(res?.data?.implements && res?.data?.implements?.length) {
                            this.$store.commit('setTeamJobOperatorMachinesDropdown', {
                                machineType: MachineType.Implement, 
                                machinesList: res?.data?.implements || []
                            })
                        }
                    }

                })

                if (getPageParam() != 'add') {
                    this.updateTeamJobOperatorsChecklist();
                }
                this.enableTeamJobDiscard();
            }
            else {
                this.applySearchJobOperationTimeout();
            }
        },
        updateTeamJobOperatorsChecklist() {
            if (this.teamJobOperators) {
                this.teamJobOperators.forEach((operator) => {
                    this.replaceOperatorChecklistByOperation(operator.operatorDetails, TeamJobOperatorChecklistResourceType.Operation)
                })
                const tempOperatorsList = this.teamJobOperators;
                this.teamJobOperators = [];
                this.teamJobOperators = tempOperatorsList;
            }
        },
        replaceOperatorChecklistByOperation(operator, resourceTypeOperation) {
            if (this.teamJobDetails && this.teamJobDetails.status == TeamJobStatusType.Completed) {
                if (operator && operator.checklists) {
                    operator.checklists = [];
                }
            } else {
                if (operator.checklists) {
                    const existingOperationChecklists = operator.checklists.find(cl => cl.resourceType == resourceTypeOperation);
                    if (existingOperationChecklists) {
                        operator.checklists = operator.checklists.filter(cl => cl.resourceType != resourceTypeOperation);
                    }
                }
                const newOperationChecklistItem = getChecklistItemFromResource(this.teamJobOperation, resourceTypeOperation);
                if (newOperationChecklistItem) {
                    operator.checklists.push(newOperationChecklistItem);
                }
            }
        },
        refeatchOperationsList(){
            this.hasMoreData = true;
            if(this.teamJobOperationsSearchText != null && (this.teamJobOperation == null || this.teamJobOperation.id == null)){
                this.teamJobOperationsSearchText = null;
                this.searchTeamJobOperations();
            }
        },
        appendMoreOperations() {
            this.clearFilterOptions();
            this.$store.dispatch('setTeamJobOperationsFilters').then(() => {
                this.$store.dispatch('getTeamJobOperations');
            })
        }
    }
}
