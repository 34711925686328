

















































































































































































































































































































































































































































































































































import $ from "jquery";
import { getShortName, clearDateTimePicker, initializeDateTimePlaceholders, parseFloatDecimal } from "@/utils/helpers";
import { mapMutations, mapActions } from "vuex";
import permissionsMixin from "@/mixins/permissions-mixin";
import scrollerMixin from "@/mixins/scroller-mixin";
import router from "@/router/index";
import { apiJobs, routeTeamJobsAdd, routeTeamJobsDetails, routeInvoicesDetails } from "@/utils/endpoints";
import signalR from "@/utils/signalr";
import moment from "moment";
import DatePicker from "vue-time-date-range-picker/dist/vdprDatePicker";
import filterParamsMixin from "@/mixins/filter-params-mixin";
import { BaseButton as Button, ButtonMiniPrimary as ListButton } from "@/components/shared/buttons/index";
import { getAssignedUserNameFromJob, removeAllLocalParamsJobs } from "@/utils/helpers/jobs-helpers";
import { ApprovalStatusType } from "@/enum/approvalStatusType";
import teamJobsListComputedMixin from './_team-jobs-list-computed';
import { TeamJobStatusType, TeamJobStatusTypeLabel } from '@/enum/teamJobStatusType';
import { UserRoleType } from '@/enum/userRoleType';
import { excelParser } from "@/utils/helpers/excel-parser-helpers";
import LoadMoreItem from "@/components/shared/load-more-item.vue";
import List from "@/components/shared/list.vue";
import ConstantValues from '@/plugins/constantValues';
import PaginationModule from "@/store/modules/pagination";
import Timers from "@/mixins/timers";
import { ExcelExportModuleType } from '@/enum/excelExportModuleType';
import requestsCancellationMixin from '@/mixins/requests-cancellation';
import { defaultDateFormat, defaultPageLimitSize, defaultPageSizeCalendarJobs } from '@/utils/constants';
import TeamJobsCalendar from '@/components/calendar/calendar-view.vue';

export default {
    name: "JobsListView",
    mixins: [
        permissionsMixin,
        scrollerMixin,
        filterParamsMixin,
        teamJobsListComputedMixin,
        Timers,
        requestsCancellationMixin,
    ],
    components: {
        DatePicker,
        Button,
        ListButton,
        LoadMoreItem,
        List,
        TeamJobsCalendar,
    },
    computed: {
        refreshListState() {
            return !this.$store.state.scrollToItem;
        },
    },
    data: function () {
        return {
            TeamJobStatusTypeLabel,
            dateInput: {
                placeholder: "Select date",
            },
            format: defaultDateFormat,
            sameDateFormat: {
                from: defaultDateFormat,
                to: defaultDateFormat,
            },
            showExportDialog: false,
            listOverflowsHorizontal: false,
            ApprovalStatusType,
            UserRoleType,
            TeamJobStatusType,
            searchTimer: null,
            searchTimerCustomersFilter: null,
            searchTimerOperatorsFilter: null,
            searchTimerFieldsFilter: null,
            searchTimerVehiclesFilter: null,
            searchTimerImplementsFilter: null,
            searchTimerProductsFilter: null,
            visibleFilters: {
                status: true,
                customer: true,
                contractor: true,
                operation: true,
                invoiceStatus: true,
                operator: true,
                field: true,
                vehicle: true,
                implement: true,
                product: true,
                dateRange: true,
            },
        };
    },
    beforeMount() {
        if (this.routedFromTimesheets || this.routedFromJobDetails) {
            this.dateInput.placeholder = this.teamJobsListFilterStartDateLocal
                ? this.getDateTimeFormat(this.teamJobsListFilterStartDateLocal)
                : "Select date";
            setTimeout(() => {
                $(".vdpr-datepicker input").first().css({ width: "120px" });
            }, 500);
        } else if (!this.$store.state.isPageRelated) {
            this.$store.state.teamJobsListModule.teamJobsList = [];
            this.clearJobFilterOption();
            this.filtersApplied = false;
            this.resetPaging();
        }
        // $(window).unbind("scroll");
    },
    beforeDestroy() {
        if (!this.$store.state.isPageRelated) {
            signalR.fnUnSubscripbe(localStorage.getItem('businessId'));
            this.clearAndResetJobPagingStates();

            if (this.$store.hasModule(this.$stringConstants('teamJobsFilterCustomersPaginationModule')))
                this.$store.unregisterModule(this.$stringConstants('teamJobsFilterCustomersPaginationModule'));
           
            if (this.$store.hasModule(this.$stringConstants('teamJobsFilterTeamMembersPaginationModule')))
                this.$store.unregisterModule(this.$stringConstants('teamJobsFilterTeamMembersPaginationModule'));

            if (this.$store.hasModule(this.$stringConstants('teamJobsFilterFieldsPaginationModule')))
                this.$store.unregisterModule(this.$stringConstants('teamJobsFilterFieldsPaginationModule'));

            if (this.$store.hasModule(this.$stringConstants('teamJobsFilterVehiclesPaginationModule')))
                this.$store.unregisterModule(this.$stringConstants('teamJobsFilterVehiclesPaginationModule'));

            if (this.$store.hasModule(this.$stringConstants('teamJobsFilterImplementsPaginationModule')))
                this.$store.unregisterModule(this.$stringConstants('teamJobsFilterImplementsPaginationModule'));

            if (this.$store.hasModule(this.$stringConstants('teamJobsFilterProductsPaginationModule')))
                this.$store.unregisterModule(this.$stringConstants('teamJobsFilterProductsPaginationModule'));
        }
        this.routedFromTimesheets = false;
        this.routedFromJobDetails = false;
        this.teamJobsDateFilterApplied = false;
    },
    created() {
        if (!this.$store.state.isPageRelated) {
            this.customerDropdownModule =  this.$stringConstants('teamJobsFilterCustomersPaginationModule');
            this.operatorDropdownModule =  this.$stringConstants('teamJobsFilterTeamMembersPaginationModule');
            this.fieldDropdownModule =  this.$stringConstants('teamJobsFilterFieldsPaginationModule');
            this.vehicleDropdownModule =  this.$stringConstants('teamJobsFilterVehiclesPaginationModule');
            this.implementDropdownModule =  this.$stringConstants('teamJobsFilterImplementsPaginationModule');
            this.productDropdownModule =  this.$stringConstants('teamJobsFilterProductsPaginationModule');

            if (!this.$store.hasModule(this.customerDropdownModule))
                this.$store.registerModule(this.customerDropdownModule, PaginationModule);

            if (!this.$store.hasModule(this.operatorDropdownModule))
                this.$store.registerModule(this.operatorDropdownModule, PaginationModule);
            
            if (!this.$store.hasModule(this.fieldDropdownModule))
                this.$store.registerModule(this.fieldDropdownModule, PaginationModule);

            if (!this.$store.hasModule(this.vehicleDropdownModule))
                this.$store.registerModule(this.vehicleDropdownModule, PaginationModule);

            if (!this.$store.hasModule(this.implementDropdownModule))
                this.$store.registerModule(this.implementDropdownModule, PaginationModule);

            if (!this.$store.hasModule(this.productDropdownModule))
                this.$store.registerModule(this.productDropdownModule, PaginationModule);

        }
    },
    mounted() {
        if (!this.$store.state.isPageRelated) {
            removeAllLocalParamsJobs();
        }
        if (this.refreshListState || this.teamJobsList.length == 0) {
            this.getJobsWithDefaultFilters();
        } else {
            this.getUrlParamsLocal();
        }
        this.$store.state.isRoutedFromReferencePage = false;

        signalR.fnSubscribeGroup(localStorage.getItem('businessId'));
        this.$store.state.dashboardTitle = this.$stringConstants('jobsTitle');

        this.$store.dispatch("getUserProfile").then(async (usr) => {
            if (usr.roles[0].type === UserRoleType.Farmer || usr.roles[0].type === UserRoleType.Contractor) {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const checkIntegration = urlSearchParams.get('checkIntegration');

                if (checkIntegration) {
                    await this.$store.dispatch('checkForIntegrations', usr.email);
                }
            }
        });

        initializeDateTimePlaceholders();


    },
    methods: {
        ...mapMutations({
            clearJobFilterOption: "setClearFilterOptions",
            resetPaging: "resetPaging",
            resetTeamJobDetails: "resetTeamJobs",
            clearInvoiceDetailsState: "setClearInvoiceData",
        }),
        ...mapActions({
            approveJobForInvoicingAsync: "approveJobForInvoicing",
        }),
        checkLoginUserType() {
            if (this.loginUserRole == UserRoleType.Contractor ||
                (this.loginUserRole == UserRoleType.Manager &&
                    this.ownerRole == UserRoleType[UserRoleType.Contractor])) {
                return true;
            }
            else {
                return false;
            }
        },
        resetJobsData() {
            this.teamJobsFilterCustomersList = [];
            this.teamJobsFilterOperationsList = [];
            this.teamJobsFilterTeamMembersList = [];
            this.teamJobsFilterFieldsList = [];
            this.teamJobsFilterVehiclesList = [];
            this.teamJobsFilterImplementsList = [];
            this.teamJobsFilterProductsList = [];
            this.teamJobsDataForExport = [];
            this.teamJobsDataListOffset = 0;
            this.teamJobsDataListTotal = 0;
            this.totalRowCount = 0;
        },
        getJobsWithDefaultFilters() {
            this.listLoading = true;
                this.$store.dispatch("getOperationsListForJobsSearch").then(() => {
                    this.$store.dispatch('setTeamMembersForJobsSearchFilters');
                    this.$store.dispatch("getTeamMembersForJobsSearch").then(() => {
                        this.clearJobFilterOption();
                        this.getUrlParamsLocal();
                        this.setFilterOptionsAndGetJobs();
                    });
                });
        },
        onJobSearchClearClicked() {
            this.teamJobsList = [];
            this.clearJobFilterOption();
            this.resetPaging();
            this.removeLocalJobsParams();
            this.getJobsWithDefaultFilters();
        },
        removeLocalJobsParams() {
            this.teamJobsListSearchText = this.removeLocalParamsMx("jobSearchQuery", "");
        },
        getUrlParamsLocal() {
            if (localStorage.getItem("jobStartDate")) {
                this.teamJobsListFilterStartDate = localStorage.getItem("jobStartDate");
                this.teamJobsDateFilterApplied = true;
            } else {
                this.teamJobsListFilterStartDate = null;
            }

            if (localStorage.getItem("jobEndDate")) {
                this.teamJobsListFilterEndDate = localStorage.getItem("jobEndDate");
                this.teamJobsDateFilterApplied = true;
            } else {
                this.teamJobsListFilterEndDate = null;
            }

            if (this.teamJobsListFilterStartDate && this.teamJobsListFilterEndDate) {
                $(".vdpr-datepicker input[type=text]")
                    .first()
                    .val(
                        this.getDateTimeFormat(this.teamJobsListFilterStartDate) +
                        " - " +
                        this.getDateTimeFormat(this.teamJobsListFilterEndDate)
                    );
                $(".vdpr-datepicker input").first().css({ width: "230px" });
            }

            this.teamJobsListSearchText = this.getLocalParamsMx("jobSearchQuery", "");
            this.teamJobsFilterStatusSelected = this.getLocalParamsMx(
                "jobStatuses",
                this.teamJobsFiltersStatusList[0].value
            );
            this.teamJobsFilterCustomerValue = this.getLocalParamsMx(
                "jobCustomerIds",
                null
            );
            this.teamJobsFilterOperationValue = this.getLocalParamsMx(
                "jobOperationsIds",
                null
            );
            this.teamJobsFilterInvoiceStatusValue = this.getLocalParamsMx(
                "jobInvoiceStatuses",
                ""
            );
            this.teamJobsFilterTeamMemberValue = this.getLocalParamsMx(
                "jobAssignedUserIds",
                null
            );
            this.teamJobsFilterFieldValue = this.getLocalParamsMx(
                "jobFieldIds",
                null
            );
            this.teamJobsFilterVehicleValue = this.getLocalParamsMx(
                "jobVehicleIds",
                null
            );
            this.teamJobsFilterImplementValue = this.getLocalParamsMx(
                "jobImplementIds",
                null
            );
            this.teamJobsFilterProductValue = this.getLocalParamsMx(
                "jobProductIds",
                null
            );
        },
        setUrlParamsLocal() {
            this.setLocalParamsMx(this.teamJobsListSearchText, "jobSearchQuery");
            this.setLocalParamsMx(this.teamJobsFilterStatusSelected, "jobStatuses");
            this.setLocalParamsMx(this.teamJobsFilterCustomerValue, "jobCustomerIds");
            this.setLocalParamsMx(this.teamJobsFilterOperationValue, "jobOperationsIds");
            this.setLocalParamsMx(this.teamJobsFilterFieldValue, "jobFieldIds");
            this.setLocalParamsMx(this.teamJobsFilterVehicleValue, "jobVehicleIds");
            this.setLocalParamsMx(this.teamJobsFilterImplementValue, "jobImplementIds");
            this.setLocalParamsMx(this.teamJobsFilterProductValue, "jobProductIds");

            this.setLocalParamsMx(
                this.teamJobsFilterInvoiceStatusValue,
                "jobInvoiceStatuses"
            );
            this.setLocalParamsMx(
                this.teamJobsFilterTeamMemberValue,
                "jobAssignedUserIds"
            );
            this.setLocalParamsMx(
                this.teamJobsFilterFieldValue,
                "jobFieldIds"
            );
            this.setLocalParamsMx(this.teamJobsListFilterStartDate, "jobStartDate");
            this.setLocalParamsMx(this.teamJobsListFilterEndDate, "jobEndDate");
        },
        applySearchJobFilter() {
            this.clearJobFilterOption();
            this.resetPaging();
            this.teamJobsList = [];
            this.filtersApplied = true;
            this.setFilterOptionsAndGetJobs();
        },
        clearAndResetJobPagingStates() {
            $(window).unbind("scroll");
            this.$store.state.teamJobsListModule.teamJobsList = [];
            this.clearJobFilterOption();
            this.resetPaging();
            this.filtersApplied = false;
        },
        openAddJobForm: function () {
            this.resetTeamJobDetails();
            router.push(routeTeamJobsAdd);
        },
        openDetailsJob(job) {
            this.resetTeamJobDetails();
            this.saveListItemID(job.id);
            this.$nextTick(() => router.push(routeTeamJobsDetails + job.id));
        },
        getDateTimeFormat: function (date) {
            return moment.utc(date).local().format("DD MMM YYYY");
        },
        filterJobsByDateRange(start, end) {
            this.teamJobsListFilterStartDate = moment(start).utc().format();
            this.teamJobsListFilterEndDate = moment(end).utc().format();
            this.teamJobsDateFilterApplied = true;
            this.clearJobFilterOption();
            this.resetPaging();
            this.teamJobsList = [];
            this.filtersApplied = true;
            this.setFilterOptionsAndGetJobs();
        },
        clearJobsDateFilter() {
            $(".vdpr-datepicker input[type=text]").first().val("");
            this.teamJobsDateFilterApplied = false;
            this.teamJobsListFilterStartDate = null;
            this.teamJobsListFilterStartDateLocal = null;
            this.teamJobsListFilterEndDate = null;
            this.clearJobFilterOption();
            this.teamJobsList = [];
            this.resetPaging();
            this.setFilterOptionsAndGetJobs();
            clearDateTimePicker(this.$refs.datePicker.$el);
        },
        filterJobsByStatus() {
            this.clearJobFilterOption();
            this.resetPaging();
            this.teamJobsList = [];
            this.filtersApplied = true;
            this.setFilterOptionsAndGetJobs();
        },
        approveJobForInvoice(jobId) {
            this.approveJobForInvoicingAsync(jobId).then((response) => {
                if (response[0] != null) {
                   this.teamJobsList.find(jb => jb.id === jobId).isApprovedForInvoicing = true
                }
            });
        },
        getOperatorNames(job: any) {
            return getAssignedUserNameFromJob(job);
        },
        getJobNumberPrefix(jobNumber: string) {
            if (jobNumber.split("#").length < 2) {
                return "#" + jobNumber;
            }
            return jobNumber.split("#")[0];
        },
        getJobNumberSuffix(jobNumber: string) {
            if (jobNumber.split("#").length == 2) {
                return "#" + jobNumber.split("#")[1];
            }
        },
        getMappedFilters() {
            return [
                { searchText: this.teamJobsListSearchText },
                { start: this.teamJobsListFilterStartDate },
                { end: this.teamJobsListFilterEndDate },
                { customerIds: this.teamJobsFilterCustomerValue },
                { operationIds: this.teamJobsFilterOperationValue },
                { operatorIds: this.teamJobsFilterTeamMemberValue },
                { textFilter: this.teamJobsFilterInvoiceStatusValue },
                { fieldIds: this.teamJobsFilterFieldValue },
                { vehicleIds: this.teamJobsFilterVehicleValue },
                { implementIds: this.teamJobsFilterImplementValue },
                { productIds: this.teamJobsFilterProductValue },
                { status: this.teamJobsFilterStatusSelected },
            ];
        },
        saveJobsDataToExcel() {
            this.$store.commit('removeIdsFromExportableData')
            excelParser().exportDataFromJSON(
                this.exportData,
                null,
                ConstantValues.defaultListExportFormat,
            )
        },
        getMoreJobsForExport() {
            this.$store.dispatch("getDataListForExport", {
                apiUrl: apiJobs,
                moduleType: ExcelExportModuleType.Jobs
            }).then(() => {
                if (this.exportDataLoaded < this.exportDataTotal) {
                    this.getMoreJobsForExport();
                } else {
                    if (this.exportData.length > 0) {
                        this.showExportDialog = false;
                        this.saveJobsDataToExcel();
                    }
                }
            });
        },
        getJobsDataForDownload() {
            this.$store.dispatch("setExportDataParameterFilters", this.getMappedFilters()).then(() => {
                this.$store.dispatch("getDataListForExport", {
                    apiUrl: apiJobs,
                    moduleType: ExcelExportModuleType.Jobs
                }).then(() => {
                    this.getMoreJobsForExport();
                });
            });
        },
        initiateExportJobs() {
            this.$store.commit('resetExportData')
            this.clearJobFilterOption();
            this.resetPaging();

            if (this.jobViewType == 'List') {
                this.showExportDialog = true;
                this.getJobsDataForDownload();
            } else {
                this.downloadCalendarJobs();
            }
        },
        downloadCalendarJobs() {
            this.$store.commit('setJobsDataListForExport', this.calendarViewJobs);
            this.saveJobsDataToExcel();
        },
        applyCustomersFilterSearchTimer() {
            if (this.searchTimerCustomersFilter) {
                clearTimeout(this.searchTimerCustomersFilter);
                this.searchTimerCustomersFilter = null;
            }
            this.searchTimerCustomersFilter = setTimeout(() => {
                this.searchCustomersByText();
            }, ConstantValues.defaultDropdownSearchTimeout); 
        },
        searchCustomersByText: function () {
            this.teamJobsFilterCustomersList = [];
            this.clearJobFilterOption();
            this.appendMoreItems('customers');
        },
        getJobsByCustomer() {
            this.applySearchJobFilter();
        },
        searchOperationsByText() {
            this.$store.dispatch("getOperationsListForJobsSearch");
        },
        getJobsByOperation() {
            this.applySearchJobFilter();
        },
        getJobsByInvoiceStatus() {
            this.applySearchJobFilter();
        },
        applyOperatorFilterSearchTimer() {
            if (this.searchTimerOperatorsFilter) {
                clearTimeout(this.searchTimerOperatorsFilter);
                this.searchTimerOperatorsFilter = null;
            }
            this.searchTimerOperatorsFilter = setTimeout(() => {
                this.searchTeammembersByText();
            }, ConstantValues.defaultDropdownSearchTimeout); 
        },
        applyFieldFilterSearchTimer() {
            if (this.searchTimerFieldsFilter) {
                clearTimeout(this.searchTimerFieldsFilter);
                this.searchTimerFieldsFilter = null;
            }
            this.searchTimerFieldsFilter = setTimeout(() => {
                this.searchFieldsByText();
            }, ConstantValues.defaultDropdownSearchTimeout); 
        },
        applyVehicleFilterSearchTimer() {
            if (this.searchTimerVehiclesFilter) {
                clearTimeout(this.searchTimerVehiclesFilter);
                this.searchTimerVehiclesFilter = null;
            }
            this.searchTimerVehiclesFilter = setTimeout(() => {
                this.searchVehiclesByText();
            }, ConstantValues.defaultDropdownSearchTimeout); 
        },
        applyImplementFilterSearchTimer() {
            if (this.searchTimerImplementsFilter) {
                clearTimeout(this.searchTimerImplementsFilter);
                this.searchTimerImplementsFilter = null;
            }
            this.searchTimerImplementsFilter = setTimeout(() => {
                this.searchImplementsByText();
            }, ConstantValues.defaultDropdownSearchTimeout); 
        },
        applyProductFilterSearchTimer() {
            if (this.searchTimerProductsFilter) {
                clearTimeout(this.searchTimerProductsFilter);
                this.searchTimerProductsFilter = null;
            }
            this.searchTimerProductsFilter = setTimeout(() => {
                this.searchProductsByText();
            }, ConstantValues.defaultDropdownSearchTimeout); 
        },
        searchTeammembersByText() {
            this.teamJobsFilterTeamMembersList = [];
            this.clearJobFilterOption();
            this.appendMoreItems('teamMembers');
        },
        searchFieldsByText() {
            this.teamJobsFilterFieldsList = [];
            this.clearJobFilterOption();
            this.appendMoreItems('fields');
        },
        searchProductsByText() {
            this.teamJobsFilterProductsList = [];
            this.clearJobFilterOption();
            this.appendMoreItems('products');
        },
        searchVehiclesByText() {
            this.teamJobsFilterVehiclesList = [];
            this.clearJobFilterOption();
            this.appendMoreItems('vehicles');
        },
        searchImplementsByText() {
            this.teamJobsFilterImplementsList = [];
            this.clearJobFilterOption();
            this.appendMoreItems('implements');
        },
        getJobsByTeamMember() {
            this.applySearchJobFilter();
        },
        getJobsByField() {
            this.applySearchJobFilter();
        },
        getJobsByVehicle() {
            this.applySearchJobFilter();
        },
        getJobsByImplement() {
            this.applySearchJobFilter();
        },
        getJobsByProduct() {
            this.applySearchJobFilter();
        },
        getLocalDateTime(dateTime) {
            return moment.utc(dateTime).local().format("DD MMM YYYY");
        },
        getJobCompletionDate(jobCompletionDate) {
            return this.getLocalDateTime(jobCompletionDate);
        },
        getShortOperationName(operationTitle) {
            return getShortName(operationTitle, 40);
        },
        getShortCustomerName(customerName) {
            return getShortName(customerName, 40);
        },
        appendMoreItems(itemType) {
            const paginationModule = this.appendItemTypes[itemType]['paginationModule'];
            this.$store.dispatch(this.appendItemTypes[itemType]['paginationSetter']);
            this.$store.dispatch(this.appendItemTypes[itemType]['paginationGetter']).then(response => this.$store.dispatch(`${paginationModule}/updateStates`, response));
        },
        setFilterOptionsAndGetJobs(filters = [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]) {
            if (!this.teamJobsListSearchText) {
                this.clearJobFilterOption();
                this.filtersApplied = false;
            }
            this.$store.dispatch('setJobsFilter', filters).then(res => {
                this.setUrlParamsLocal();
                this.$store.dispatch('getTeamJobsList');
            })
        },
        saveListItemID(id) {
            this.$store.state.scrollToItem = id;
        },
        handleViewInvoice(teamJobDetails) {
            const {service, id} = teamJobDetails.invoiceReference;
            if (service === 0) {
                this.$store.state.isRoutedFromReferencePage = true;
                this.saveListItemID(teamJobDetails.id);
                this.$nextTick(() => router.push(routeInvoicesDetails + id));
            } else {
                this.$store.dispatch('getInvoiceUrl', {id, service}).then(response => response && window.open(response, '_blank'));
            }
        },
        getBillingQuantity(billingQuantity) {
            return parseFloatDecimal(billingQuantity);
        },
        clearDateTimeStates() {
            $(".vdpr-datepicker input[type=text]").first().val("");
            this.teamJobsDateFilterApplied = false;
            this.teamJobsListFilterStartDate = null;
            this.teamJobsListFilterStartDateLocal = null;
            this.teamJobsListFilterEndDate = null;
            this.teamJobsListFilterEndDateLocal = null;
            if (this.$refs.datePicker?.$el)
                clearDateTimePicker(this.$refs.datePicker.$el);
            this.removeLocalParamsMx("jobStartDate", null);
            this.removeLocalParamsMx("jobEndDate", null);
        },
        async switchToListView() {
            this.$store.state.noData = false;
            this.resetPaging();
            const filterOptions = await this.$store.dispatch('updateOffsetAndLimitValues', [0, defaultPageLimitSize]);
            this.$store.state.filterOptions = filterOptions;
            this.$store.state.listCount = 0;
            if (this.listLoading) return;

            this.teamJobsList = [];
            this.jobViewType = 'List';
            
            setTimeout(() => { if (!this.listLoading) { this.getJobsWithDefaultFilters(); } }, 100);
        },
        async switchToCalendarView() {
            if (this.listLoading) return;

            this.clearDateTimeStates();
            this.$store.state.listCount = 0;
            let filterOptions = await this.$store.dispatch('updateOffsetAndLimitValues', [0, defaultPageSizeCalendarJobs]);

            const excludeParams = ['Start', 'End', 'Preference', 'Statuses', 'InvoicingStatus'];
            filterOptions = filterOptions.filter(([key]) => !excludeParams.includes(key));
            this.$store.state.filterOptions = filterOptions;
            this.jobViewType = 'Calendar';
        },
        refreshJobs() {
            if (this.jobViewType === 'List') {
                this.teamJobsList = [];
                this.resetPaging();
                this.getJobsWithDefaultFilters();
            } else {
                this.calendarViewJobEvents = [];
                this.$store.dispatch('getTeamJobsList');
                this.switchToCalendarView();
            }
        },
        connectToXero() {
            this.$store.dispatch("startIntegrationAuthentication", 'xero');
        }
    },
};
