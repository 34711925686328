import { ExcelExportModuleType } from "@/enum/excelExportModuleType";
import ConstantValues from "@/plugins/constantValues";
import requests from "@/utils/requests";
import buildUrl from "build-url";

export const actions = { 

 async setExportDataParameterFilters({commit, state}, filters) {
  if(filters && filters.length) {
    filters.forEach((f) => {
     for (const [key, value] of Object.entries(f)) {
       if(key && value && key != 'textFilter') {
        commit('setFilterOptions', [`${key}`, value])
       }
       if(key === 'textFilter') {
        state.exportDataTextFilters += value
       }
      }
     });
    }
  },

 async getDataListForExport({ commit, state, rootState, dispatch }, {apiUrl, moduleType}) {
   dispatch("jsonToQueryString", rootState.filterOptions);

   let pagingUrl = (rootState.tempUrl ? rootState.tempUrl : '') + (rootState.tempUrl ? '&' : '?') + ConstantValues.limitQuery + '=' + ConstantValues.defaultPageLimitForJobsExport + '&offset=' + state.exportDataOffset + (state.exportDataTextFilters !== 'null' ? state.exportDataTextFilters : '')

   if (rootState.teamJobsListModule.teamJobsFilterStatusSelected) {
     pagingUrl += "&statuses=" + rootState.teamJobsListModule.teamJobsFilterStatusSelected
   }

   const url = buildUrl(rootState.baseUrl, {
       path: apiUrl + pagingUrl
   });
   
   const result = await requests.getData(url, true);

   if (result != null && 'data' in result) {
       if (result.data != null) {
           state.exportDataOffset = result.data.value.length;
           state.exportDataTotal = result.data.size;
           if (result.data.value.length > 0) {
             switch(moduleType) {
              case ExcelExportModuleType.Invoices: 
               commit('setInvoicesDataListForExport', result.data.value); break;
              case ExcelExportModuleType.Jobs:
               commit('setJobsDataListForExport', result.data.value); break;
              case ExcelExportModuleType.Users:
               commit('setUsersDataListForExport', result.data.value); break;
              case ExcelExportModuleType.Businesses:
               commit('setBusinessesDataListForExport', result.data.value); break;
              default: return;
             }
           }
       }
   }
 },
 
}