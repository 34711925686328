import { getComputedProperties } from "@/utils/helpers/computed-generator";

const computedProperties: Record<string, Array<string>> = {
    'teamJobsModule': [
        'teamJobScheduledDate',
        'teamJobStatus',
        'teamJobInstructions',
        'tempTeamJobInstructions',
        'tempTeamJobNotes',
        'teamJobAttachments'
    ]
}

export default {
    name: 'TeamJobsGenericComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties)
    }
}